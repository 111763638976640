import React from 'react';
import logo from '../img/logo-dark1.jpg';

function Start() {
  return (
    <div className='start-container d-flex'>
        <img src={logo} alt="logo" />
    </div>
  )
}

export default Start;